//
import React, { FC, ReactNode, useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useRouter } from 'next/router';

// Internationalization
import { IntlProvider } from 'react-intl';
import en from '@/utils/locales/en/common.json';
import fr from '@/utils/locales/fr/common.json';
import { Endpoints, Locales } from '@/utils/types/common';
import { API_ENDPOINTS, setApiEndpoints } from '@/utils/constants/constants';
import axiosConfigApiInstance from '../Axios/ConfigApiAxiosInstance';

const messages = {
  en,
  fr,
};

type Props = {
  children: ReactNode;
  endpoints: Endpoints;
  extraMessages?: Locales;
};

const AppContext: FC<Props> = ({ children, endpoints, extraMessages }) => {
  const { locale } = useRouter();

  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 30,
            retry: false,
            refetchOnWindowFocus: true,
            refetchOnMount: true,
          },
        },
      })
  );

  useEffect(() => {
    setApiEndpoints(endpoints);
    axiosConfigApiInstance.defaults.baseURL = API_ENDPOINTS.crud;

    if (extraMessages) {
      Object.assign(messages.en, extraMessages.en);
      Object.assign(messages.fr, extraMessages.fr);
    }
      
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {
        // issue: local can be undefined,but this is already hundled by Next.js
        //@ts-ignore
        <IntlProvider locale={locale} messages={messages[locale]}>
          {children}
        </IntlProvider>
      }

      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
// export default MyApp;

export default AppContext;
