import { useIntl } from 'react-intl';

const useTranslation = () => {
  const trans = useIntl();
  const t = (id: string) => trans.messages[id];
  return {
    t: (id: string): string =>
      trans.messages[id] ? trans.messages[id].toString() : id,
    ...trans,
  };
};

export default useTranslation;
