import { 
  IconEdit, 
  IconTrash, 
  IconDuplicate, 
  IconMagnifier, 
  Button, 
  Tooltip, 
  LinkButton 
} from '@phpcreation/frontend-components-react';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { Field, IFQCN_BUI } from '@/utils/types/common';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import parse from 'html-react-parser';

//this
import { ColumnsType } from 'antd/lib/table';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import useTranslation from '../translation/useTranslation';

//import parse from 'html-react-parser';

const useListingColumns = (
  resource: string,
  defaultColumns: Field[],
  fqcn_bui: IFQCN_BUI,
  untouched?: boolean
) => {
  const { filters, fields, selectedActions } = useListingStore();

  const { setModalDelete, setModalEdit, setModalDuplicate } = useModalsStore();

  //translate
  const { t } = useTranslation();

  const Actions = {
    title: t('Actions'),
    dataIndex: 'actions',
    key: 'actions',
    render: (text: string, record: any) => (
      <div
        id="button_table_actions"
        className="flex items-center space-x-3  justify-start max-w-32"
      >
        {record.selectedActions.includes(t('show')) && (
          <Tooltip content={t('show')} side="bottom" delayDuration={400}>
            <div>
              <Link href={`/${resource}/${record.id}`}>
                <LinkButton
                  id={getFQCN(fqcn_bui, 'list', 'actions:show-button')}
                  href={`/${resource}/${record.id}`}
                  variant="neutral"
                  size="xsmall"
                  icon={
                    <IconMagnifier className="w-4 h-4 scale-[0.9] text-gray-500" />
                  }
                  onClick={() => {}}
                ></LinkButton>
              </Link>
            </div>
          </Tooltip>
        )}
        {record.selectedActions.includes(t('edit')) && (
          <Tooltip content={t('edit')} side="bottom" delayDuration={400}>
            <div>
              <Button
                id={getFQCN(fqcn_bui, 'list', 'actions:edit-button')}
                onClick={event => {
                  event.stopPropagation();
                  setModalEdit(true, record);
                }}
                variant="neutral"
                size="xsmall"
                icon={
                  <IconEdit className="w-4 h-4 scale-[0.9] text-gray-400 hover:text-blue-600" />
                }
              />
            </div>
          </Tooltip>
        )}
        {record.selectedActions.includes(t('duplicate')) && (
          <Tooltip content={t('duplicate')} side="bottom" delayDuration={400}>
            <div>
              <Button
                id={getFQCN(fqcn_bui, 'list', 'actions:duplicate-button')}
                onClick={event => {
                  event.stopPropagation();

                  setModalDuplicate(true, record);
                }}
                variant="neutral"
                bgcolor="gray-100"
                size="xsmall"
                icon={
                  <IconDuplicate className="w-4 h-4 scale-[0.9] text-gray-500 hover:text-blue-600" />
                }
              />
            </div>
          </Tooltip>
        )}
        {record.selectedActions.includes(t('delete')) && (
          <Tooltip content={t('delete')} side="bottom" delayDuration={400}>
            <div>
              <Button
                id={getFQCN(fqcn_bui, 'list', 'actions:delete-button')}
                onClick={event => {
                  event.stopPropagation();

                  setModalDelete(true, record);
                }}
                variant="neutral"
                size="xsmall"
                icon={
                  <IconTrash className="w-4 h-4 scale-[0.9] text-red-400 " />
                }
              />
            </div>
          </Tooltip>
        )}
      </div>
    ),
  };

  const [Columns, setColumns] = useState<any[]>([]);
  useEffect(() => {
    let array_fields = defaultColumns
      .map((item: Field) => {
        return {
          title: t(item.title),
          dataIndex: item.key,
          sorter: item.sortable ? item.sortable : false,
          render:
            item.render != untouched
              ? item.render
              : (text: any, record: any) =>
                  item.type == 'url' ? (
                    <Link
                      href={`/${
                        item.key.split('.').length > 1
                          ? item.key.split('.')[0] + 's'
                          : resource
                      }/${_.get(record, item.key)}`}
                    >
                      <a id={getFQCN(fqcn_bui, 'list', item.key + '-link')}>
                        {_.get(record, item.key)}
                      </a>
                    </Link>
                  ) : (
                    <span
                      id={getFQCN(fqcn_bui, 'list', item.key + '-text')}
                      onClick={() => {
                        console.log('record=', record);
                      }}
                    >
                      {parse(_.get(record, item.key))}
                    </span>
                  ),
        };
      }) //@ts-ignore
      .concat([Actions]);

    // reflect columns selction
    const array_fields_selected = array_fields.filter(i => {
      console.log("fields",fields);
      console.log("i",i);
      console.log('inclusion:', fields?.includes(i.dataIndex.split('.')[0]));
      return fields?.includes(i.dataIndex.split('.')[0]);
    });
    console.log('returned_columns=', array_fields_selected);

    setColumns(untouched ? array_fields : array_fields_selected);
  }, [fields]);

  return Columns;
};

export default useListingColumns;
