//interceptors for  api calls
import axios from 'axios';
import { API_ENDPOINTS } from '../../utils/constants/constants';
import { refreshToken } from '../helpers/methods';

import { getCookie, deleteCookie } from 'cookies-next';

const axiosConfigApiInstance = axios.create();

// set the base url
axiosConfigApiInstance.defaults.baseURL = API_ENDPOINTS.crud;

// would be usefull if we have a way to store the token

// Request interceptor for API calls
axiosConfigApiInstance.interceptors.request.use(
  async config => {
    let jwt = getCookie('token');
    config.headers = {
      Authorization: `Bearer ` + jwt,
    };
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosConfigApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    console.log(`--------------------\n erorr ${error} \n -------------`);
    const status = error.response ? error.response.status : null;

    console.log({ status });

    const original_req = error.config;
    original_req.headers['Conent-Type'] = `application/json; Charset=UTF-8`;

    console.log({ original_req });

    // retry untill not status 401
    if (status == 401) {
      if (!window.location.href.includes('login')) {
        deleteCookie('token');

        window.location.href = '/login?from=' + window.location.pathname;
      }
      console.log('wrong code');
    }

    return Promise.reject(error);
  }
);

export default axiosConfigApiInstance;

// Set default headers to common_axios ( as Instance )
