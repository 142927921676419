import { IconLightning, Button, Dropdown, Input } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { IFQCN_BUI } from '@/utils/types/common';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';

type Props = {
  defaultActions: string[];
  resource: string;
  fqcn_bui: IFQCN_BUI;
};

const DropdownActions: FC<Props> = ({ defaultActions, resource, fqcn_bui }) => {
  const { selectedActions, setSelectedActions } = useListingStore();

  const [actions, setActions] = useState<string[]>([]);

  const router = useRouter();
  useEffect(() => {
    if (router.isReady) {
      setSelectedActions(
        router.query.actions
          ? router.query.actions.toString().split(',')
          : defaultActions
      );
      setActions(
        router.query.actions
          ? router.query.actions.toString().split(',')
          : defaultActions
      );
    }
  }, [router.query]);

  //translate
  const { t } = useTranslation();

  return (
    <Dropdown
      id={getFQCN(fqcn_bui, 'actions')}
      title={t("Actions")}
      position="right"
      useChevron
      size="small"
      icon={<IconLightning className="w-4 h-4 text-gray-500" />}
    >
      <div className="p-1  ">
        <div className="__ Section_select flex flex-col space-y-1 p-1 ">
          <span
            id={getFQCN(fqcn_bui, 'actions', 'select-text')}
            className="text-xs text-gray-600 border-b pb-1 "
          >
            {t('Selected actions')}
          </span>
          <div className="py-1">
            {defaultActions.map(elt => (
              <div
                key={defaultActions.indexOf(elt)}
                className="__Row_1 flex  space-x-3  items-center   "
              >
                <div className="w-5  flex justify-start   ">
                  <Input
                    id={getFQCN(
                      fqcn_bui,
                      'actions',
                      elt.toLocaleLowerCase() + '-checkbox'
                    )}
                    checked={actions?.includes(elt)}
                    onChange={() => {
                      if (actions?.includes(elt))
                        setActions(actions.filter(e => e !== elt));
                      else setActions([...actions, elt]);
                    }}
                    // size="small"
                    type="checkbox"
                  />
                </div>
                <span className="font-medium text-gray-500 text-sm pb-1 capitalize">
                  {t(elt)}
                </span>
              </div>
            ))}
          </div>
          <div className=" flex justify-center ">
            <Button
              id={getFQCN(fqcn_bui, 'exports', 'apply-button')}
              title={t("apply")}
              variant="secondary"
              color="gray-500"
              size="xsmall"
              onClick={() => setSelectedActions(actions)}
            />
          </div>
        </div>
      </div>
    </Dropdown>
  );
};

export default DropdownActions;
