import * as XLSX from 'xlsx';
export const downloadxls = (data: object[], filename: string) => {
  console.log(data);
  const ws = XLSX.utils.json_to_sheet(data);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'data');
  /* generate XLSX file and send to client */
  XLSX.writeFile(wb, filename + '.xlsx');
};
export const downloadCSV = (json: object[], filename: string) => {
  const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
  const workbook: XLSX.WorkBook = {
    Sheets: { data: worksheet },
    SheetNames: ['data'],
  };
  XLSX.writeFile(workbook, filename + '.csv');
};
