import React, { useState, useEffect } from "react"

function useDebounce<T>(rawValue: T, timer?: number) {
    const [debouncedValue, setDebouncedValue] = useState<T>(rawValue)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setDebouncedValue(rawValue)
        }, timer || 300)
        // component unmounting
        return () => { clearTimeout(timeout) }
    }, [rawValue])

    return debouncedValue

}

export default useDebounce