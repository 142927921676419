import { Modal } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import { useDelete } from '@/lib/services/api-config/crud';
import React, { FC, useState } from 'react';

type Props = {
  resource: string;
};

const ModalDelete: FC<Props> = ({ resource }) => {
  const { modalDelete, setModalDelete } = useModalsStore();

  const {
    isLoading: isLoadingDeleteParameter,
    isError: isErrorDeleteParameter,
    isSuccess: isSuccessDeleteParameter,
    data: DataDeleteParameter,
    mutate: delete_parameter,
  } = useDelete(resource);

  //translate
  const { t } = useTranslation();
    
  return (
    <Modal
      title={t('Delete ') + t(resource.toString().slice(0, resource.length - 1))}
      isDanger
      isOpen={modalDelete.isOpen}
      handleClose={() => setModalDelete(false, null)}
      isButtonOkLoading={isLoadingDeleteParameter}
      hanldleOK={() => {
        delete_parameter(modalDelete.data.id);
      }}
      handleCancel={() => setModalDelete(false, null)}
    >
      <div className="flex flex-col space-y-3">
        <p>
          {t("Do you really want to delete this")}{' '}
          {t(resource.toString().slice(0, resource.length - 1))} ?
        </p>
        <div></div>
        <div>
          <span>
            ID : <b>{modalDelete.data?.id}</b>
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDelete;
