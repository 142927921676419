import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import React, { FC, useEffect } from 'react';
import {
  useCreate,
  useDelete,
  useReadAll,
  useUpdate,
} from '@/lib/services/api-config/crud';

import { Table } from '@phpcreation/frontend-components-react';
import useListingColumns from '@/hooks/shared/listing/useListingColumns';
import { Field, IFQCN_BUI } from '@/utils/types/common';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { useRouter } from 'next/router';

type Props = {
  resource: string;
  defaultColumns: Field[];
  fqcn_bui: IFQCN_BUI;
  id?: string;
};

const TableListing: FC<Props> = ({ resource, defaultColumns, fqcn_bui, id }) => {
  const { closeAllModals } = useModalsStore();
  const {
    fields,
    filters,
    selectedActions,
    setSort,
    setOrder,
    setPageSize,
    setPage,
  } = useListingStore();

  const {
    isLoading: issLoading,
    isError,
    isSuccess,
    data,
    refetch,
  } = useReadAll(resource, filters, true);

  const queryClient = useQueryClient();

  const {
    isLoading: isLoadingCreate,
    isError: isErrorCreate,
    isSuccess: isSuccessCreate,
    data: DataCreate,
    mutate: create_,
  } = useCreate(resource);
  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
    data: DataUpdate,
    mutate: update_,
  } = useUpdate(resource);
  const {
    isLoading: isLoadingDelete,
    isError: isErrorDelete,
    isSuccess: isSuccessDelete,
    data: DataDelete,
    mutate: delete_,
  } = useDelete(resource);

  const ismutating = useIsMutating();

  const router = useRouter();
  useEffect(() => {
    const TO = setTimeout(() => {
      closeAllModals();
    }, 600);

    return () => clearTimeout(TO);
  }, [ismutating]);

  useEffect(() => {
    console.log('hello', ismutating);
  }, [ismutating]);
  return (
    <div className=""
    id={id}>
      <Table
        style={{maxWidth: "200px"}}
        id={getFQCN(fqcn_bui, 'list')}
        rowKey="id"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              router.push('/' + resource + '/' + record.id);
            }, // click row
          };
        }}
        columns={useListingColumns(resource, defaultColumns, fqcn_bui)}
        // columns={useListingColumns(resource).filter(col =>
        //   fields?.includes(col.key)
        // )}
        loading={
          isLoadingCreate ||
          issLoading ||
          isLoadingDelete ||
          isLoadingUpdate ||
          ismutating == 1
        }
        dataSource={
          data
            ? [
                ...data['hydra:member'].map((e: any) => ({
                  ...e,
                  selectedActions,
                })),
              ]
            : []
        }
        onChange={(pagination, filters, sorter) => {
          console.log(pagination);
          setPage(pagination.current);
          setPageSize(pagination.pageSize ? pagination.pageSize : 10);

          console.log(sorter);
          if (sorter.hasOwnProperty('column')) {
            //TODO: fix types
            //@ts-ignore
            if (sorter.order === 'ascend') {
              //@ts-ignore
              setSort(sorter.field);
              setOrder('asc');
              console.log('asc clicked !');
            }
            //@ts-ignore
            if (sorter.order === 'descend') {
              //@ts-ignore
              setSort(sorter.field);
              setOrder('desc');
            }
            //@ts-ignore
            if (sorter.order == undefined) {
              setSort(undefined);
              setOrder(undefined);
              console.log('null clicked');
            }
          }
        }}
        pagination={{
          pageSize: filters.pageSize ? filters.pageSize : 25,
          current: filters.page ? filters.page : 1,
          showSizeChanger: true,
          total: data && data['hydra:totalItems'],
          pageSizeOptions: [5, 25, 100, 500, 1000],
          position: ['bottomLeft'],
        }}
      />
    </div>
  );
};

export default TableListing;
