import { FormInput, FormInputItem } from '@/utils/types/common';
import React, { FC } from 'react';
import ModalAdd from './ModalAdd';
import ModalDelete from './modalDelete';
import ModalDuplicate from './ModalDuplicate';
import ModalEdit from './ModalEdit';

type Props = {
  resource: string;
  formInputs: FormInputItem[];
};

const ModalsCRUD: FC<Props> = ({ resource, formInputs }) => {
  return (
    <div>
      <ModalAdd resource={resource} formInputs={formInputs} />
      <ModalEdit resource={resource} formInputs={formInputs} />
      <ModalDuplicate resource={resource} formInputs={formInputs} />
      <ModalDelete resource={resource} />
    </div>
  );
};

export default ModalsCRUD;
