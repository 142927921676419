import { create } from 'zustand';
import { combine } from 'zustand/middleware';

const useListingStore = create(
  combine(
    {
      filters: {
        specific: {} as object,
        id: null as string | null,
        q: null as string | undefined | null,
        limit: null as number | null | undefined,
        page: null as number | null | undefined,
        pageSize: 25 as number | null | undefined,
        offset: null as number | null | undefined,
        sort: undefined as undefined | null | string,
        order: undefined as undefined | null | 'asc' | 'desc',
        qField: 'id' as string,
      },

      fields: [] as string[] | null,
      selectedActions: [] as string[],
    },

    set => ({
      setSpecific: (field: string, value: string | boolean | number | null) =>
        set(s => {
          const obj = {
            filters: { ...s.filters },
          };
          //@ts-ignore
          obj.filters.specific[field] = value;
          return obj;
        }),

      setId: (givenId: string) =>
        set(s => ({ filters: { ...s.filters, id: givenId } })),
      setQ: (query: string) =>
        set(s => ({ filters: { ...s.filters, q: query } })),
      setQField: (qfield: string) =>
        set(s => ({ filters: { ...s.filters, qField: qfield } })),

      setSort: (field: string | undefined | null) =>
        set(s => ({
          filters: { ...s.filters, sort: field },
        })),
      setOrder: (order: 'asc' | 'desc' | undefined | null) =>
        set(s => ({
          filters: { ...s.filters, order: order },
        })),
      setPage: (page: number | null | undefined) =>
        set(s => ({ filters: { ...s.filters, page } })),
      setPageSize: (pageSize: number | null | undefined) =>
        set(s => ({ filters: { ...s.filters, pageSize } })),
      setOffset: (offset: number | null | undefined) =>
        set(s => ({ filters: { ...s.filters, offset } })),

      setSelectedActions: (actions: string[]) =>
        set(s => ({ selectedActions: actions })),
      setLimit: (limit: number | null | undefined) =>
        set(s => ({ filters: { ...s.filters, limit } })),
      setFields: (fields: string[]) => set({ fields }),
      addField: (f: string) => set(s => ({ fields: s.fields?.concat([f]) })),
      removeField: (f: string) =>
        set(s => ({ fields: s.fields?.filter(elt => elt !== f) })),

      clearFilters: () =>
        set({
          filters: {
            specific: {},
            id: null,
            q: null,
            limit: undefined,
            page: undefined,
            offset: undefined,
            sort: undefined,
            order: undefined,
            pageSize: 25,
            qField: 'id',
          },
        }),
      clearAll: () =>
        set({
          filters: {
            specific: {},
            id: null,
            q: null,
            limit: undefined,
            page: undefined,
            offset: undefined,
            sort: undefined,
            order: undefined,
            pageSize: 25,
            qField: 'id',
          },
          fields: undefined,
          selectedActions: [],
        }),
    })
  )
);

export default useListingStore;
