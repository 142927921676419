import { Tour } from '@phpcreation/frontend-components-react';
import useTourStore from '@/lib/global-stores/shared/tours/useTourStore';
import { overrideTargetID } from '@/lib/helpers/fqcn/methods';
import React, { useState } from 'react';
import { ACTIONS, STATUS, Step } from 'react-joyride';

type Props = {
  tourId: string | number;
  steps: Step[];
};

const TourDashboard: React.FC<Props> = ({ tourId, steps }) => {
  const { tours, runTour, stopTour } = useTourStore();
  const tour = tours.find(t => t.id == tourId);
  return (
    <Tour
      continuous
      run={tour?.isRun}
      showSkipButton
      steps={overrideTargetID(steps, 'target')}
      callback={data => {
        const { action, type, index, status } = data;
        if (
          // If close button clicked then close the tour
          action === ACTIONS.CLOSE ||
          // If skipped or end tour, then close the tour
          (status === STATUS.SKIPPED && tour?.isRun) ||
          status === STATUS.FINISHED
        ) {
          stopTour(tourId.toLocaleString());
        }
      }}
      showProgress
    />
  );
};

export default TourDashboard;
