import { Select, Input, Modal } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import { submitDataModifier } from '@/lib/helpers/methods';
import { get_all_resource, get_single_resource, useUpdate } from '@/lib/services/api-config/crud';
import { FormInput, FormInputItem } from '@/utils/types/common';
import React, { FC, useEffect, useState } from 'react';
import parse from 'html-react-parser';

type Props = {
  resource: string;
  formInputs: FormInputItem[];
};

const ModalEdit: FC<Props> = ({ resource, formInputs }) => {
  const {
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
    isSuccess: isSuccessUpdate,
    data: DataUpdate,
    mutate: update_resource,
  } = useUpdate(resource);

  const { modalEdit, setModalEdit } = useModalsStore();

  const [FormData, setFormData] = useState<any>({});

  useEffect(() => {
    if (modalEdit.data) {
      console.log(modalEdit.data);

      let key_value_formData = {};

      formInputs.forEach(async i => {
        console.log('->', i.name, modalEdit.data[i.name]);
        if (i.type == 'text') {
          //@ts-ignore
          key_value_formData[i.name] =
            //@ts-ignore
            _.get(modalEdit.data, i.name);
          //@ts-ignore
          console.log('with get', i, _.get(modalEdit.data, i.name));
        }

        if (i.type == 'select_async') {
          //@ts-ignore
          key_value_formData[i.name] = {
            label: '',
            //@ts-ignore
            value: _.get(modalEdit.data, i.name).id,
          }

          //@ts-ignore
          const resource = await get_single_resource(`${i.targetResourceAsync}`, _.get(modalEdit.data, i.name).id);

          //@ts-ignore
          key_value_formData[i.name].label = i.resourceAsyncLabelParam ? 
            parse(resource[i.resourceAsyncLabelParam]) :
            //@ts-ignore
            _.get(modalEdit.data, i.name).id;
        }
      });

      setFormData(key_value_formData);
    }
  }, [modalEdit]);

  //translate
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Edit ') + t(resource.toString().slice(0, resource.length - 1))}
      isOpen={modalEdit.isOpen}
      handleClose={() => setModalEdit(false, null)}
      isButtonOkLoading={isLoadingUpdate}
      hanldleOK={() => {
        console.log(FormData);
        let adjusted_formData = submitDataModifier(FormData, formInputs);
        //@ts-ignore
        update_resource({ id: modalEdit.data.id, ...adjusted_formData });
      }}
      handleCancel={() => setModalEdit(false)}
    >
      <div className="flex flex-col items-start space-y-1">
        {formInputs.map((field: FormInputItem, index: any) => {
          if (field.type == 'text')
            return (
              <div
                key={index}
                className="flex flex-col items-start -space-y-2 text-sm"
              >
                <label>{t(field.label)}</label>

                <Input
                  // size="large"
                  onChange={e =>
                    setFormData({
                      ...FormData,
                      [field.name]: e.target.value,
                    })
                  }
                  value={FormData[field.name]  ?? ''}
                  placeholder={`${t(field.placeholder ?? '')}`}
                />
              </div>
            );
          else
            return (
              <div key={index} className="flex flex-col items-start -space-y-2 text-sm">
                <label>{t(field.label)}</label>

                <Select
                  variant="async"
                  placeholder={field.placeholder}
                  defaultOptions
                  cacheOptions={true}
                  loadOptions={(input, callback) => {
                    get_all_resource(
                      `${field.targetResourceAsync}`,
                      { id: input },
                      false
                    )
                      .then(res =>
                        res.map((i: any) => {
                          console.log('res: ', i);
                          return { 
                            label: field.resourceAsyncLabelParam ? parse(i[field.resourceAsyncLabelParam]) : i.id,
                            value: i.id 
                          };
                        })
                      )
                      .then(data => callback(data));
                  }}
                  onChange={(newIds: any) => {
                    console.log({ newIds });
                    setFormData({
                      ...FormData,
                      [field.name]: newIds,
                    });
                  }}
                  value={FormData[field.name]}
                />
              </div>
            );
        })}
      </div>
    </Modal>
  );
};

export default ModalEdit;
