import { API_ENDPOINTS} from '@/utils/constants/constants';
import axios from 'axios';

export const getApiStatus = async () => {
  try {
    return await axios.get(API_ENDPOINTS.status).then(res => res);
  } catch (e) {
    return [];
  }
};

/**
 * Function for test as the hook useReadSingle
 * @param resource
 * @param id
 * @param jwt
 * @returns
 */
export const getReadSingle = async (
  resource: string,
  id: Number,
  jwt: string
) =>
  axios
    .get(API_ENDPOINTS.crud + '/' + resource + '/' + id, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then(res => res);

/**
 * Function to the the hook as useReadAll
 * @param resource
 * @param jwt
 * @returns
 */
export const getReadAll = async (resource: string, jwt: string) =>
  axios
    .get(API_ENDPOINTS.crud + '/' + resource, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then(res => res);

export const getReadSingleJsonPath = async (
  resource: string,
  jsonPath: JSON,
  jwt: string
) =>
  axios
    .get(API_ENDPOINTS.crud + '/' + resource + '/' + jsonPath, {
      headers: { Authorization: `Bearer ${jwt}` },
    })
    .then(res => res);

export const getRequestFromLink = async (link: string) =>
  axios.get(link).then(res => res);

export const getRequestFromLinkToken = async (link: string, jwt: string) =>
  axios
    .get(link, { headers: { Authorization: `bearer ${jwt}` } })
    .then(res => res);
