import { FormInput, FormInputItem } from '@/utils/types/common';
import axios from 'axios';
import { Step } from 'react-joyride';

export const refreshToken = (): string => {
  return 'hello ';
};

import { StoreApi, UseBoundStore } from 'zustand';
import useListingStore from '../global-stores/shared/listing/useListingStore';

const checker_multi = (value: any, field?: string, Default?: any) => {
  if (value != null || value != undefined) {
    // case of muliple values '?id[]=2&id[]=3'
    if (value.toString().split(',').length > 1) {
      let exp = '';
      let arr = value.toString().split(',');

      for (let i = 0; i < arr.length; i++) {
        exp = exp + field + '[]=' + arr[i];
        if (i < arr.length - 1) exp = exp + '&';
      }
      return exp;
    }
    // case simple value
    return value + '&';
  }
  return Default ?? '';
};

const get_specifics = (obj: object) => {
  console.log(obj);

  if (!obj) return '';

  // returned epression
  let exp = '';

  // lets get the object keys as an array elements : {id:1,tenant:2} =>['id','tenant']
  let arr_props = Object.keys(obj);
  //@ts-ignore
  console.log({ arr_props });

  // now We will iterate through the keys of the object aka elements of the array of props
  for (let i = 0; i < arr_props.length; i++) {
    //@ts-ignore
    console.log('items', obj[arr_props[i]], arr_props[i]);

    // in case the property value is not '2,3' but only a single value
    //@ts-ignore
    if (obj[arr_props[i]].toString().split(',').length == 1) {
      exp =
        exp +
        arr_props[i] +
        '[]=' +
        //@ts-ignore
        checker_multi(obj[arr_props[i]].toString(), arr_props[i], '');
      continue;
    }

    //@ts-ignore
    exp = exp + checker_multi(obj[arr_props[i]].toString(), arr_props[i], '');
    if (i < arr_props.length - 1) exp = exp + '&';
  }
  return exp;
};

export const get_urlQS = (resource: string, filters: any) => {
  const {
    q,
    id,
    specific,
    page,
    pageSize,
    limit,
    offset,
    sort,
    order,
    qField,
  } = filters;

  console.log('---get_specifics---', get_specifics(specific));

  // SEARCH COLUMN IS __HARDCODED
  let query_params = `page=${checker_multi(
    page,
    'page',
    1
  )}&range=${checker_multi(pageSize, 'range', 25)}&limit=${checker_multi(
    limit
  )}&order%5B${sort}%5D=${order}&offset=${checker_multi(
    offset
  )}&id[]=${checker_multi(id)}&${get_specifics(specific)}`;

  if (q)
    query_params += `&search=${checker_multi(q, '')}`;

  return query_params;
};

export function submitDataModifier(
  formData: object,
  formInputs: FormInputItem[]
) {
  let newFormData = {};

  formInputs.forEach(item => {

    if (item.type == 'text') {
      if (item.prefixOnSubmit)
        // @ts-ignore
        newFormData[item.name] =
          // @ts-ignore
          item.prefixOnSubmit + formData[item.name];
      // @ts-ignore
      else newFormData[item.name] = formData[item.name];
    }

    if (item.type == 'select_async') {
      if (item.prefixOnSubmit)
        // @ts-ignore
        newFormData[item.name] =
          // @ts-ignore
          item.prefixOnSubmit + formData[item.name].value;
      // @ts-ignore
      else newFormData[item.name] = formData[item.name].value;
    }
  });

  console.log('here are form data modified, template is=', newFormData);

  return newFormData;
}
