import React, { useState, useEffect, FC } from 'react';
import { IconFilter, Button, Card, PageLayout } from '@phpcreation/frontend-components-react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useReadAll } from '@/lib/services/api-config/crud';

import DropdownFields from '@/components/sections/shared/features/DropdownFields';
import DropdownExports from '@/components/sections/shared/features/DropdownExports';
import DropdownActions from '@/components/sections/shared/features/DropdownActions';

import TableListing from '@/components/sections/shared/listing/TableListing';
import ModalsCRUD from '@/components/sections/shared/modals/ModalsCRUD';

import TourDashboard from '@/components/sections/shared/tours/Dashboard';
import SectionHeaderActions from '@/components/sections/shared/layout/SectionHeaderActions';
import ListingSearch from '@/components/sections/shared/Search/ListingSearch';

import useInitializer from '@/hooks/shared/listing/useInitializer';
import useTourStore from '@/lib/global-stores/shared/tours/useTourStore';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';

//UNSHARED
import {
  Navigation,
  Field,
  FormInput,
  IFQCN_BUI,
  FilterFieldItem,
  FormInputItem,
} from '@/utils/types/common';
import { Step } from 'react-joyride';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import MainFilters from '../shared/filters/main';
import useTranslation from '@/hooks/shared/translation/useTranslation';

const _RESOURCE: string = 'tenants';

type ListingProps = {
  resource: string;
  appNav: Navigation[];
  mainTourSteps: Step[];
  fieldsExport: string[];
  fieldsAll: string[];
  formModalInputs: FormInputItem[];
  mainColumns: Field[];
  fqcn_bui: IFQCN_BUI;
  qField?: string;
  filterFields: FilterFieldItem[];
  defaultActions?: string[];
};

const Listing: FC<ListingProps> = ({
  resource,
  appNav,
  mainTourSteps,
  fieldsAll,
  fieldsExport,
  formModalInputs,
  mainColumns,
  fqcn_bui,
  qField,
  filterFields,
  defaultActions,
}) => {
  const _RESOURCE: string = resource;

  const { tours, createTour, runTour } = useTourStore();
  const tourId = `${_RESOURCE}-main`;

  const { filters, clearAll, clearFilters } = useListingStore();

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { isLoading: isInitialLoading } = useReadAll(_RESOURCE, {}, true);
  const { refetch } = useReadAll(_RESOURCE, filters, false);

  // check if there is query params
  const { isInitialized } = useInitializer();

  // Always refetch on componenent mounting
  useEffect(() => {
    refetch();
    createTour(tourId);
  }, []);

  const { t } = useTranslation();
  
  return (
    <PageLayout
      fqcn_bui={fqcn_bui}
      title={_RESOURCE}
      appNav={appNav}
      breadcrumbNav={[
        { label: 'Home', url: '/' },
        { label: _RESOURCE, url: `/${_RESOURCE}` }
      ]}
    >
      <SectionHeaderActions
        fqcn_bui={fqcn_bui}
        tourId={tourId}
        resource={_RESOURCE}
      />
      <Card loading={isInitialLoading} rows={1} cols={4}
      id ={getFQCN(fqcn_bui,'_filters')}>
        <div className="flex justify-between items-center flex-wrap space-x-0">
          <ListingSearch
            qField={qField ?? 'id'}
            id={getFQCN(fqcn_bui, 'search', 'input')}
          />

          <DropdownFields
            fqcn_bui={fqcn_bui}
            resource={_RESOURCE}
            defaultFields={[...fieldsAll, 'actions']}
          />
          <DropdownExports
            fqcn_bui={fqcn_bui}
            resource={_RESOURCE}
            fields={fieldsExport}
          />
          <Button
            title={t("filters")}
            variant="primary"
            id={getFQCN(fqcn_bui, 'filters', 'filters-button')}
            icon={
              <IconFilter
                className={`w-4 h-4${
                  isFiltersVisible ? ' text-gray-600' : ' text-white'
                } `}
              />
            }
            className={`${
              isFiltersVisible && 'bg-gray-200 hover:bg-gray-200 text-gray-600'
            }`}
            onClick={() => {
              setIsFiltersVisible(!isFiltersVisible);
            }}
          />
          <DropdownActions
            fqcn_bui={fqcn_bui}
            resource={_RESOURCE}
            defaultActions={
              defaultActions ?? [t('edit'), t('delete'), t('show'), t('duplicate')]
            }
          />
          <MainFilters
            isVisible={isFiltersVisible}
            filterFields={filterFields}
            resource={resource}
          />
        </div>
      </Card>
      <br />
      <Card loading={isInitialLoading} rows={4} title={t(`${_RESOURCE} Listing`)}>
        <div className="w-full overflow-x-scroll md:w-auto">
          <TableListing
            fqcn_bui={fqcn_bui}
            resource={_RESOURCE}
            defaultColumns={mainColumns}
          />
        </div>
      </Card>
      <ModalsCRUD resource={_RESOURCE} formInputs={formModalInputs} />
      {tours.find(t => t.id == tourId)?.isRun && (
        <TourDashboard tourId={tourId} steps={mainTourSteps} />
      )}
    </PageLayout>
  );
};

export default Listing;
