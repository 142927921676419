import { Card } from '@phpcreation/frontend-components-react';
import React, { FC } from 'react';
import _ from 'lodash';
import { FieldShow, IFQCN_BUI } from '@/utils/types/common';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import useTranslation from '@/hooks/shared/translation/useTranslation';

type Props = {
  loading: boolean;
  resource: string;
  displayedFields: FieldShow[];
  data: any;
  fqcn_bui: IFQCN_BUI;
};

const General: FC<Props> = ({
  resource,
  displayedFields,
  data,
  loading,
  fqcn_bui,
}) => {
  const { t } = useTranslation();
  return (
    <Card
      loading={loading}
      rows={8}
      cols={2}
      title={`${t(resource.toString().slice(0, resource.length - 1))} ${t(
        'information'
      )}`}
    >
      <div
        id={getFQCN(fqcn_bui, 'general')}
        className="__Wrapper flex flex-col space-y-3 p-2"
      >
        {displayedFields.map((item: FieldShow, key: number) => (
          <div
            id={getFQCN(fqcn_bui, 'general', item.key)}
            key={key}
            className="flex flex-col "
          >
            <span
              id={getFQCN(fqcn_bui, 'general', item.key + '-title')}
              className="__label text-sm text-gray-500"
            >
              {t(item.key)}
            </span>
            <span
              id={getFQCN(fqcn_bui, 'general', item.key + '-value')}
              className="__value font-medium text-base "
            >
              {_.get(data, item.path)}
            </span>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default General;
