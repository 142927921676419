import { FilterFieldItem, IResourceFilters } from '@/utils/types/common';
import toast from 'react-hot-toast';

export const copy_url_with_filters = (filters: IResourceFilters) => {
  let path: string = window.location.host + window.location.pathname;
  console.log(path);

  let queryparams: string = '';

  Object.keys(filters)
    .filter(k => k != 'specific')
    .forEach(key => {
      // if (key == 'list_id_tenant') return;
      //@ts-ignore
      console.log(key, filters[key]);
      //@ts-ignore
      queryparams = queryparams + `${key}=${filters[key] ?? ''}&`;
    });

  //@ts-ignore
  Object.keys(filters.specific).forEach(key => {
    //@ts-ignore
    queryparams =
      //@ts-ignore
      queryparams + `specific.${key}=${filters.specific[key] ?? ''}&`;
  });

  let final_url: string = path + '?' + queryparams;
  navigator.clipboard.writeText(final_url.substring(0, final_url.length - 1));
  console.table({ final_url });
  toast.success('Url Copied!');
};

export const generate_multiNqueryString = (key: string, array: any[]) => {
  let str = '';
  array.forEach(e => (str = str + '&' + key + '[]=' + e.toString()));
  return str;
};

export const Get_select_value = (item: FilterFieldItem, selection: any) => {
  if (item.isMulti) {
    return selection[item.key]
      ? selection[item.key].split(',').map((i: any) => ({ label: i, value: i }))
      : [];
  }
  return selection[item.key]
    ? { label: selection[item.key], value: selection[item.key] }
    : [];
};

export const HanldeChange = () => {};
