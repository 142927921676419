import axios from 'axios';
import { setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from '@tanstack/react-query';
import { API_ENDPOINTS } from '@/utils/constants/constants';
import useTranslation from '@/hooks/shared/translation/useTranslation';

interface Ilogin {
  username: string;
  password: string;
}

/**
 * getJwtToken
 * @param Ilogin { username, password }
 * @returns string as JwtToken (285 chars)
 */
export const getJwtToken = async ({ username, password }: Ilogin) =>
 await axios.post(
  API_ENDPOINTS.auth,
  {
    username: username,
    password: password
  })
  .then(res => res.data );
  


export default function useLogin() {
  const router = useRouter();
  const { t } = useTranslation();

  return useMutation(
    ['login'],
    (credentials: Ilogin) => {
      const processedFormValues = {
        username: credentials.username.trim(),
        password: credentials.password.trim(),
      };
      console.log(credentials);
      return getJwtToken(processedFormValues);
    },
    {
      onSuccess: data => {
        setCookie('token', data.token);
        let toastId = toast.success(t('Authenticated'), {
          position: 'bottom-right',
        });
        setTimeout(() => {
          toast.remove(toastId);
          router.push(
            router.query.from ? router.query.from.toString() : '/dashboard'
          );
        }, 1000);
      },
      onError: err => {
        console.error(err);
        toast.error(t('Wrong Data'));
      },
    }
  );
}

export function useGetToken() {
  const router = useRouter();
  const { t } = useTranslation();

  return useMutation(
    ['login'],
    (credentials: Ilogin) => {
      const processedFormValues = {
        username: credentials.username.trim(),
        password: credentials.password.trim(),
      };
      console.log(credentials);
      return getJwtToken(processedFormValues);
    },
    {
      onSuccess: data => {
        setCookie('token', data);
        let taostId = toast.success('Success!');
        setTimeout(() => {
          toast.remove(taostId);
        }, 1000);
      },
      onError: () => {
        toast.error(t('Wrong Data'));
      },
    }
  );
}

//remove consolelog cedentials
