import { Endpoints } from "../types/common";

//API
export const API_ENDPOINTS: Endpoints = {
  crud: '',
  auth: '',
  status: '',
  documentation: '',
};

export const setApiEndpoints = (endpoints: Endpoints) => {
  API_ENDPOINTS.crud = endpoints.crud;
  API_ENDPOINTS.auth = endpoints.auth;
  API_ENDPOINTS.status = endpoints.status;
  API_ENDPOINTS.documentation = endpoints.documentation;
};
