import React, { useState, useEffect, useRef, RefObject, FC } from 'react';
import Link from 'next/link';
import toast, { Toaster } from 'react-hot-toast';
import { 
  Input, 
  IconLockFilled, 
  IconMailFilled, 
  Logo, 
  Button, 
  WithSkeletonLoader, 
  LoginSkeleton, 
  CopyButton,
  LoginSvg 
} from '@phpcreation/frontend-components-react';
import useLogin from '@/hooks/auth/useLogin';
import { useRouter } from 'next/router';
import axios from 'axios';
import { setCookie } from 'cookies-next';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import FooterLogin from '@/components/sections/shared/login/FooterLogin';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { IFQCN_BUI } from '@/utils/types/common';
import { API_ENDPOINTS } from '@/utils/constants/constants';

type IUserLogin = {
  username: string;
  password: string;
};

type Props = {
  devUsername?: string;
  devPassword?: string;
  fqcn_bui: IFQCN_BUI;
  sampleResource: string;
  cycle: string | undefined;
};

const Login: FC<Props> = ({
  fqcn_bui,
  devUsername,
  devPassword,
  sampleResource,
  cycle,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { query, isReady } = router;

  const InputRef: RefObject<HTMLInputElement> = useRef(null);
  const InputPasswordRef: RefObject<HTMLInputElement> = useRef(null);
  const [isAutoTested, setIsAutoTested] = useState<boolean>(false);
  const [isLoadingToken, setIsLoadingToken] = useState<boolean>(false);

  const [formData, setFormData] = useState<IUserLogin>({
    username: '',
    password: '',
  });

  const { mutate: login, isLoading, isError, isSuccess, data } = useLogin();
  const {
    mutate: login_auto,
    isLoading: isLoading_query_auto,
    isError: isError_auto,
    isSuccess: isSuccess_auto,
    data: data_auto,
  } = useLogin();

  useEffect(() => {
    if (isReady) {
      if (query.username) {
        setIsLoadingToken(true);
        setFormData({
          username: query.username.toString(),
          password: query.password ? query.password.toString() : '',
        });
        login_auto({
          username: query.username.toString(),
          password: query.password ? query.password.toString() : '',
        });
        setTimeout(() => {
          setIsAutoTested(true);
        }, 500);
      }

      // check if the token is valid

      if (query.token) {
        setIsLoadingToken(true);
        axios
          .get(API_ENDPOINTS.crud + sampleResource + 's', {
            headers: {
              Authorization: `Bearer ` + query.token,
            },
          })
          .then(data => {
            console.log({ data });
            setIsAutoTested(true);
            setIsLoadingToken(false);

            setCookie('token', query.token);
            toast.success('Redirecting...');
            setTimeout(() => {
              router.push('/dashboard');
            }, 800);
          })
          .catch(e => {
            setTimeout(() => {
              setIsAutoTested(true);
              setIsLoadingToken(false);
            }, 1000);
            setTimeout(() => toast.error('Invalid Token'), 1300);
          });
      }
    }
  }, [isReady]);

  // Password input focus on error
  useEffect(() => {
    // if (InputRef.current) InputRef.current.focus();
    if (InputPasswordRef.current) {
      InputPasswordRef.current.value = '';
      InputPasswordRef.current.focus();
    }
  }, [isError]);

  return (
    <WithSkeletonLoader
      pageTitle={t(`Log in`)}
      isLoading={(isLoadingToken || isLoading_query_auto) && !isAutoTested}
      //   isLoading={true}
      skeleton={<LoginSkeleton />}
    >
      <div
        id={getFQCN(fqcn_bui, '', '')}
        className="grid md:grid-cols-2 grid-cols-1 min-h-screen "
        style={{ fontFamily: 'Roboto' }}
      >
        {/* <LoadingScreen isLoading={isButtonLoading} /> */}
        <Toaster />

        <main
          id={getFQCN(fqcn_bui, 'main', '')}
          className="flex flex-col items-center space-y-2 p-5  md:p-7 md:py-10    bg-white shadow-lg rounded-xl text-gray-700"
        >
          <div
            id={getFQCN(fqcn_bui, 'main', 'leftside-authform-logo-image')}
            className="__LOGO w-full py-1 flex items-start"
          >
            <Link href="/">
              <div className="transform  scale-75 hover:scale-[0.78]  px-2  py-2 mb-3 transition-transform ease-linear  border-l  border-gray-200  hover:border-blue-600   cursor-pointer">
                <Logo />
              </div>
            </Link>
          </div>

          <div
            id={getFQCN(fqcn_bui, 'leftside-authform', '')}
            className="__Center lg:pt-10 pt-5"
          >
            <h2
              id={getFQCN(fqcn_bui, 'leftside-authform', 'login-title')}
              className="text-2xl md:text-4xl text-gray-900 font-semibold"
            >
              {t(`Log in`)}
            </h2>

            <form
              id={getFQCN(fqcn_bui, 'leftside-authform')}
              onSubmit={e => {
                e.preventDefault();
                login(formData);
              }}
              className="flex flex-col items-center    w-full  "
            >
              <Input
                id={getFQCN(fqcn_bui, 'leftside-authform', 'username-input')}
                label={t(`Username`)}
                autoFocus={true}
                inputRef={InputRef}
                state={isError ? 'error' : 'default'}
                // size="large"
                value={formData.username}
                required
                name="username"
                onChange={(e: any) =>
                  setFormData({ ...formData, username: e.target.value })
                }
                placeholder={t(`Username`)}
                icon={<IconMailFilled className="w-4 h-4" />}
                iconPosition="right"
              />

              <Input
                id={getFQCN(fqcn_bui, 'leftside-authform', 'password-input')}
                label={t(`Password`)}
                inputRef={InputPasswordRef}
                state={isError ? 'error' : 'default'}
                message={isError ? 'Invalid Credentials!' : ''}
                // size="large"
                required
                type="password"
                name="password"
                value={formData.password}
                onChange={e =>
                  setFormData({ ...formData, password: e.target.value })
                }
                placeholder={t(`Password`)}
                icon={<IconLockFilled className="w-4 h-4" />}
                iconPosition="right"
              />

              <div className="__Form__Submit flex flex-col space-y-4 w-full">
                <div className="flex-1">
                  <Button
                    className='w-full'
                    isLoading={isLoading}
                    labelLoading={t(`Loading...`)}
                    variant="primary"
                    size="large"
                    title={t('Log in')}
                    // type="submit"
                    id={getFQCN(fqcn_bui, 'leftside-authform', 'login-button')}
                  />
                </div>
                {
                  // Remove comment when forgot password page is working

                  //<a
                  //  href="#"
                  //  id={getFQCN(fqcn_bui, 'leftside-authform', 'forget-link')}
                  //  className="text-xs text-gray-500 hover:text-gray-700 justify-center"
                  //>
                  //</div>  {t(`Forgot password?`)}
                  //</a>
                }
              </div>
            </form>
          </div>
          {devPassword && devUsername && cycle && cycle == 'dev' && (
            <div
              id={getFQCN(fqcn_bui, 'leftside-devnote')}
              className="__DEFAULTS flex flex-col items-baseline w-96   p-8     rounded-lg"
            >
              <span className="text-xs font-medium text-gray-500 text-center  py-4   ">
                {t(`For dev`)}
              </span>

              <div className="flex items-center justify-between w-full   space-x-2  p-1 rounded-lg ">
                <label className="text-xs font-medium text-gray-600 flex-1">
                  {t(`Username`)}
                </label>
                <span className="font-medium text-xs flex-1 flex justify-center">{devUsername}</span>
                <span className="flex-1 flex justify-end"><CopyButton content={devUsername} /></span>
              </div>

              <div className="flex items-center   justify-between w-full  space-x-2  p-1 rounded-lg ">
                <label className="text-xs font-medium text-gray-600 flex-1">
                  {t(`Password`)}
                </label>
                <span className="font-medium text-xs flex flex-1 justify-center">{devPassword}</span>
                <span className="flex-1 flex justify-end"><CopyButton content={devPassword} /></span>
              </div>
            </div>
          )}
          <div style={{ zIndex: 1 }}>
            <FooterLogin id={getFQCN(fqcn_bui,'footer')}/>
          </div>
        </main>

        <div className="Left-side md:block hidden bg-gradient-to-r from-blue-500 to-blue-500 justify-center items-center">
          <div className="w-full h-full flex justify-center items-center ">
            <div className="w-3/5 h-3/5  ">
              <div className=" bg-white w-28 px-5 py-2 rounded-lg ml-20 my-2">
                <Logo />
              </div>
              <LoginSvg />
            </div>
          </div>
        </div>
      </div>
    </WithSkeletonLoader>
  );
};

export default Login;
