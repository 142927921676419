import React, { useState, useEffect, FC, useRef, ReactNode } from 'react';
import { useRouter } from 'next/router';
import toast from 'react-hot-toast';

import {
  IconEdit,
  IconPrint,
  IconTrash,
  PageLayout,
  Button,
  Expander
} from '@phpcreation/frontend-components-react';
import General from '@/components/sections/shared/show/General';

import ModalsCRUD from '@/components/sections/shared/modals/ModalsCRUD';

import useInitializer from '@/hooks/shared/listing/useInitializer';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';

import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import { useReadSingle } from '@/lib/services/api-config/crud';
import {
  Field,
  FieldShow,
  FormInput,
  FormInputItem,
  IFQCN_BUI,
  ISideBox,
  Navigation,
} from '@/utils/types/common';
import TableListingSide from '../shared/listing/TableListingSide';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import useTranslation from '@/hooks/shared/translation/useTranslation';

type ShowProps = {
  resource: string;
  appNav: Navigation[];
  formInputs: FormInputItem[];
  displayFields: FieldShow[];
  sideBoxs?: ISideBox[];
  fqcn_bui: IFQCN_BUI;
  customActions?: ReactNode;
  customShows?: ReactNode;
};

const Show: FC<ShowProps> = ({
  resource,
  appNav,
  formInputs,
  displayFields,
  sideBoxs,
  fqcn_bui,
  customActions,
  customShows,
}) => {
  const { t } = useTranslation();

  const { filters, clearAll } = useListingStore();
  const { modalDelete, setModalDelete } = useModalsStore();

  const [isExpanded, setIsExpanded] = useState(false);

  const router = useRouter();
  const { id } = router.query;

  //@ts-ignore
  const {
    isLoading: isInitialLoading,
    isError,
    isSuccess: isSuccessInitial,
    data: data_show,
    refetch,
  } = useReadSingle(resource, Number(id));

  // Always refetch on componenent mounting
  useEffect(() => {
    // in case of deletion close modal and change router
    if (id && isError) {
      setModalDelete(false);

      setTimeout(() => {
        toast.success('Redirecting...');
      }, 400);
      setTimeout(() => router.push('/' + resource), 900);
    }
    // unmounting effect to remove all filters
    return () => {
      clearAll();
    };
  }, [isError]);

  // check if there is query params
  const { isInitialized } = useInitializer();

  if (!id) return null;
  return (
    <PageLayout
      fqcn_bui={fqcn_bui}
      title={resource}
      appNav={appNav}
      breadcrumbNav={[
        { label: 'Home', url: '/' },
        { label: resource, url: `/${resource}`},
        { label: id ? id.toString() : 'd', url: '#' },
      ]}
    >
      <div
        id={getFQCN(fqcn_bui, 'actions')}
        className="flex flex-wrap justify-center  md:justify-end pb-4"
      >
        <div className='flex items-center justify-between w-1/3 space-x-20'>
          <Button
            className='flex flex-1 justify-center'
            title={t("edit")}
            variant="primary"
            id={getFQCN(fqcn_bui, 'actions', 'edit-button')}
            icon={<IconEdit className="w-4 h-4 text-blue-login" />}
            onClick={() => router.push('/' + resource + '/' + id + '/edit')}
          />
          <Button
            className='flex flex-1 justify-center'
            id={getFQCN(fqcn_bui, 'actions', 'delete-button')}
            title={t("delete")}
            variant="secondary"
            color="red-500"
            icon={<IconTrash className="w-4 h-4 text-red-500" />}
            onClick={() => {
              setModalDelete(true, { id });
            }}
          />
          <Button
            className='flex flex-1 justify-center'
            id={getFQCN(fqcn_bui, 'actions', 'print-button')}
            title={t("print")}
            variant="neutral"
            color="gray-500"
            icon={<IconPrint className="w-4 h-4 text-gray-500" />}
            onClick={() => {
              setIsExpanded(true);
              setTimeout(() => window.print(), 1000);
            }}
          />
          {customActions}
        </div>
      </div>
      <div className="flex  flex-wrap  sm:space-x-5 ">
        <div className="flex-auto ">
          <General
            fqcn_bui={fqcn_bui}
            loading={isInitialLoading}
            data={data_show}
            resource={resource}
            displayedFields={displayFields}
          />
        </div>

        <div className="flex-1 max-w-xl flex flex-col space-y-8">
          {sideBoxs &&
            sideBoxs.map((sidebox: ISideBox, index: number) => (
              <Expander
                id={getFQCN(
                  fqcn_bui,
                  'general',
                  sidebox.subresource + '-expander'
                )}
                key={index}
                title={t(sidebox.subresource)}
                maxHeight="screen"
                isHeaderHighlighted
                isExpanded={isExpanded}
                border
              >
                <div
                  className="w-full  md:w-auto"
                  id={getFQCN(
                    fqcn_bui,
                    'general',
                    sidebox.subresource + '-table'
                  )}
                >
                  <TableListingSide
                    fqcn_bui={fqcn_bui}
                    resource={sidebox.subresource}
                    id={id.toString()}
                    defaultColumns={sidebox.defaultColumns}
                  />
                </div>
              </Expander>
            ))}
          {customShows}
        </div>
      </div>
      <ModalsCRUD resource={resource} formInputs={formInputs} />
    </PageLayout>
  );
};

export default Show;
