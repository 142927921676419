import { Select, Input, Modal } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useModalsStore from '@/lib/global-stores/shared/modals/useModalsStore';
import { submitDataModifier } from '@/lib/helpers/methods';
import { useCreate, get_all_resource } from '@/lib/services/api-config/crud';
import { FormInput, FormInputItem } from '@/utils/types/common';
import React, { FC, useState } from 'react';
import parse from 'html-react-parser';

type Props = {
  resource: string;
  formInputs: FormInputItem[];
};

const ModalAdd: FC<Props> = ({ resource, formInputs }) => {
  const {
    isLoading: isLoadingCreateResource,
    isError: isErrorCreateResource,
    isSuccess: isSuccessCreateResource,
    data: DataCreateResource,
    mutate: create_resource,
  } = useCreate(resource);

  const { modalAdd, setModalAdd } = useModalsStore();

  const [FormData, setFormData] = useState<any>({});

  //translate
  const { t } = useTranslation();

  return (
    <Modal
      title={t('Add ') + t(resource.toString().slice(0, resource.length - 1))}
      isOpen={modalAdd.isOpen}
      handleClose={() => setModalAdd(false)}
      isButtonOkLoading={isLoadingCreateResource}
      hanldleOK={() => {
        console.log("form data");
        console.log(FormData);

        let adjusted_formData = submitDataModifier(FormData, formInputs);

        console.log("adjusted form data");
        console.log(adjusted_formData);

        //@ts-ignore
        create_resource(adjusted_formData);
      }}
      handleCancel={() => setModalAdd(false)}
    >
      <div className="flex flex-col items-start space-y-1">
        {formInputs.map((field: FormInputItem, index: any) => {
          if (field.type == 'text')
            return (
              <div
                key={index}
                className="flex flex-col items-start -space-y-2 text-sm"
              >
                <label>{t(field.label)}</label>

                <Input
                  // size="large"
                  onChange={e =>
                    setFormData({
                      ...FormData,
                      [field.name]: e.target.value,
                    })
                  }
                  value={FormData[field.name] ?? ''}
                  placeholder={`${t(field.placeholder ?? '')}`}
                />
              </div>
            );
          else
            return (
              <div key={index} className="flex flex-col items-start -space-y-2 text-sm">
                <label>{t(field.label)}</label>

                <Select
                  variant="async"
                  placeholder={field.placeholder}
                  defaultOptions
                  cacheOptions={true}
                  loadOptions={(input, callback) => {
                    get_all_resource(
                      `${field.targetResourceAsync}`,
                      { id: input },
                      false
                    )
                      .then(res =>
                        res.map((i: any) => {
                          return { 
                            label: field.resourceAsyncLabelParam ? parse(i[field.resourceAsyncLabelParam]) : i.id,
                            value: i.id 
                          };
                        })
                      )
                      .then(data => callback(data));
                  }}
                  onChange={(newIds: any) => {
                    console.log({ newIds });
                    setFormData({
                      ...FormData,
                      [field.name]: newIds,
                    });
                  }}
                  value={FormData[field.name]}
                />
              </div>
            );
        })}
      </div>
    </Modal>
  );
};

export default ModalAdd;
