import _DEFAULTS from '@/utils/defaults/fqcn.json';
import { IFQCN_BUI } from '@/utils/types/common';
import { Step } from 'react-joyride';

/**
 * @see  {@link path http://localhost:3000/dev/quality_assurance/naming-convention-pfqcn }
 * @param bui Bundle unit interface object
 * @param Part part of the doc
 * @param Element element belonging to the part of the doc
 * @returns return the fqcn as a string in the format 'pfqnc_phprconfig_appBundle_main_menu_links'
 */

const getFQCN = (
  bui: IFQCN_BUI,
  Part: string | null,
  Element?: string | null
): string => {
  let fqcn = `${_DEFAULTS.Indicator}:${_DEFAULTS.Application}:${bui.Bundle}:${
    bui.Unit
  }:${bui.Interface}${Part ? ':' + Part : ''}${Element ? ':' + Element : ''}`;
  return fqcn;
};

/**
 * Goal: workaround Joyride not support of dot '.' and double dots ":" inside target.[since it is using css selectors]
 * Replaces the `property` value of each object in `arrayObjects` that matches the `searchValue`
 * with the `replaceValue`.
 * @param {object[]} arrayObjects - An array of objects to be modified.
 * @param {string} property - The property name to be modified in each object.
 * @returns {any[]} An array of modified values of the specified `property`.
 */
function overrideTargetID(arrayObjects: Step[], property: 'target') {
  return arrayObjects.map(i => {
    // destruct i so we can have the values, not the reference copy (doing newObj=i give the reference !!)
    let newObj = { ...i };
    newObj[property] = i[property]
      .toString()
      .replace(/[.]/g, '\\.')
      .replace(/[:]/g, '\\:');
    return newObj;
  });
}

const addEscape = (item: string): string => {
  return item.replace(/[.]/g, '\\.').replace(/[:]/g, '\\:');
};

export { getFQCN, addEscape, overrideTargetID };
