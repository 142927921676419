import { IconGlyphExport, Button, Dropdown } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { downloadCSV, downloadxls } from '@/lib/helpers/listing/common';
import { useReadAll } from '@/lib/services/api-config/crud';
import { IFQCN_BUI } from '@/utils/types/common';
import React, { FC } from 'react';

type Props = {
  resource: string;
  fields: any[];
  fqcn_bui: IFQCN_BUI;
};

const DropdownExports: FC<Props> = ({ fields, resource, fqcn_bui }) => {
  const { filters } = useListingStore();
  const { data } = useReadAll(resource, filters, false);
  //translate
  const { t } = useTranslation();
  
  return (
    <Dropdown
      position="left"
      useChevron
      icon={<IconGlyphExport fill="gray" className="w-4 h-4 text-blue-login" />}
      title={t("exports")}
      size="small"
      id={getFQCN(fqcn_bui, 'exports')}
    >
      <div
        id={getFQCN(fqcn_bui, 'exports', 'list')}
        className="text-gray-500 flex-col p-1 space-y-2 divide-y px-2"
      >
        <Button
          size="xsmall"
          variant="neutral"
          id={getFQCN(fqcn_bui, 'exports', 'list:excel-link')}
          icon={
            <IconGlyphExport fill="blue" className="w-4 h-4 text-blue-login" />
          }
          onClick={() => {
            const modified_json = data.map((tn: any) => {
              const obj = Object.fromEntries(fields.map(f => [f, tn.f]));
              for (const key of fields) {
                obj[key] = tn[key];
              }
              return obj;
            });
            console.log({ modified_json });
            downloadxls(modified_json, resource);
          }}
          title="Excel"
        />
        <Button
          id={getFQCN(fqcn_bui, 'exports', 'list:csv-link')}
          size="xsmall"
          variant="neutral"
          icon={
            <IconGlyphExport fill="blue" className="w-4 h-4 text-blue-login" />
          }
          onClick={() => {
            const modified_json = data.map((tn: any) => {
              const obj = Object.fromEntries(fields.map(f => [f, tn.f]));
              for (const key of fields) {
                obj[key] = tn[key];
              }
              return obj;
            });
            console.log({ modified_json });
            downloadCSV(modified_json, resource);
          }}
          title="CSV"
        />
      </div>
    </Dropdown>
  );
};

export default DropdownExports;
