import { Button, FeedbackModal } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import React, { FC, useState } from 'react';
import LangSelector from '../translation/LangSelector';

type FooterLoginProps = {
  id?: string;
};

const FooterLogin: FC<FooterLoginProps> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <footer
      id={id}
      className="__bottom  h-full flex flex-col items-center  space-y-3 justify-end"
    >
      <div id={id + '-languageSelector'}>
        <LangSelector />
      </div>
      <div className="">
        <Button
        id={id + '-report-button'}
          size="xsmall"
          title={t(`Report a problem`)}
          onClick={() => setIsOpen(true)}
          variant="neutral"
        />
        <FeedbackModal isOpen={isOpen} hanldeClose={() => setIsOpen(false)} />
      </div>
      <span className="text-sm text-gray-500">
        {t(`Powered`)} <b> PHPCreation Inc.</b>
      </span>
    </footer>
  );
};

export default FooterLogin;
