import { Select } from '@phpcreation/frontend-components-react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

type Props = {};

const languages = [
  { label: 'Français', value: 'fr' },
  { label: 'English', value: 'en' },
];
const LangSelector: FC<Props> = (props: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  return (
    <div>
      {' '}
      <Select
        value={languages.filter(l => l.value == router.locale)}
        _size1="small"
        menuPlacement="top"
        placeholder={t(`choose a language`)}
        options={languages}
        onChange={(newValue: any) => {
          const asPath = router.asPath;
          router.push(
            { pathname: router.pathname, query: router.query },
            asPath,
            { locale: newValue.value }
          );
        }}
      />
    </div>
  );
};

export default LangSelector;
