import React, { useState, useEffect, FC } from 'react';
import Link from 'next/link';
import { PageLayout, Card, LinkButton } from '@phpcreation/frontend-components-react';
import { appNav } from '@/utils/misc/navigations/index';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import { IFQCN_BUI, IHomepageItem, Navigation } from '@/utils/types/common';
import { getFQCN } from '@/lib/helpers/fqcn/methods';

type HomepageProps = {
  appNav: Navigation[];
  heroTitle: string;
  heroDesc: string;
  fqcn_bui: IFQCN_BUI;
  arraySections: IHomepageItem[];
};

const Homepage: FC<HomepageProps> = ({
  appNav,
  heroTitle,
  heroDesc,
  fqcn_bui,
  arraySections,
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  useEffect(() => {
    const TO = setTimeout(() => setIsLoading(false), 290);
    return () => clearTimeout(TO);
  }, []);

  return (
    <PageLayout
      fqcn_bui={{
        Bundle: 'appBunlde',
        Unit: 'dashboard',
        Interface: 'homepage',
      }}
      title={t(`Dashboard`)}
      appNav={appNav}
      breadcrumbNav={[
        { label: 'Home', url: '/' },
        { label: 'Dashboard', url: '/dashboard' }
      ]}
    >
      <div id={getFQCN(fqcn_bui, 'hero')} className="Hero p-4      ">
        <h1
          id={getFQCN(fqcn_bui, 'hero', 'title')}
          className="text-center p-2 text-xl md:text-2xl font-semibold text-gray-800"
        >
          {t(heroTitle)}
        </h1>

        <p
          id={getFQCN(fqcn_bui, 'hero', 'description')}
          className="text-gray-500 text-center  md:text-sm text-xs"
        >
          {t(heroDesc)}
        </p>
      </div>

      <div className="cards  grid lg:grid-cols-2   xl:grid-cols-3 grid-cols-1 space-x gap-14 place-items-center p-3">
        {arraySections.map((item: IHomepageItem, index: number) => (
          <Card key={index}>
            <div
              id={getFQCN(fqcn_bui, item.resource + '-card')}
              className="Card1 flex flex-col items-center space-y-2 2xl:w-96 h-96 justify-around   p-1 bg-white  rounded-lg"
            >
              <div id={getFQCN(fqcn_bui, item.resource + '-card', 'icon')}>
                {item.icon}{' '}
              </div>
              <h3
                className="text-gray-800 font-semibold md:text-xl  p-3"
                id={getFQCN(fqcn_bui, item.resource + '-card', 'title')}
              >
                {t(item.title)}
              </h3>
              <p
                id={getFQCN(fqcn_bui, item.resource + '-card', 'description')}
                className="md:text-sm text-xs text-gray-500"
              >
                {t(item.desc)}
              </p>
              <Link href={item.href} passHref legacyBehavior>
                <LinkButton
                  href={item.href}
                  id={getFQCN(fqcn_bui, item.resource + '-card', 'button')}
                  variant="primary"
                  size="small"
                  title={t(`Manage`)}
                  onClick={() => {}}
                />
              </Link>
            </div>
          </Card>
        ))}
      </div>
    </PageLayout>
  );
};

export default Homepage;
