import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  create_resource,
  delete_resource,
  get_all_resource,
  get_single_resource,
  update_resource,
} from './fetchers';
import { IResourceFilters } from '@/utils/types/common';

export function useReadAll(
  resource: string,
  filters: IResourceFilters,
  raw: boolean
) {
  return useQuery([resource, 'list', filters, raw], () =>
    get_all_resource(resource, filters, raw)
  );
}

export function useReadSingle(resource: string, resource_id: number | string) {
  return useQuery([resource, 'detail', resource_id], () =>
    get_single_resource(resource, resource_id)
  );
}

export function useCreate(resource: string) {
  const queryClient = useQueryClient();

  return useMutation([resource], data => create_resource(resource, data), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([resource]);
      toast.success('Successfuly created!');
    },
    onError: (data, variables) => {
      queryClient.invalidateQueries([resource]);
      toast.error('Something went wrong!');
    },
  });
}

export function useUpdate(resource: string) {
  const queryClient = useQueryClient();

  return useMutation([resource], data => update_resource(resource, data), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([resource]);
      toast.success('Successfuly updated!');
    },
    onError: (data, variables) => {
      queryClient.invalidateQueries([resource]);
      toast.error('Something went wrong!');
    },
  });
}

export function useDelete(resource: string) {
  const queryClient = useQueryClient();

  return useMutation([resource], (id: any) => delete_resource(resource, id), {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([resource]);
      toast.success('Successfuly deleted!');
    },
    onError: (data, variables) => {
      queryClient.invalidateQueries([resource]);
      toast.error('Something went wrong!');
    },
  });
}
