import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';

interface Params {
  defaultFields?: string[];
}

const useInitializer: Function = () => {
  const {
    filters,
    setOrder,
    setSort,
    setPageSize,
    setQ,
    setSpecific,
    clearAll,
  } = useListingStore();

  const router = useRouter();
  const [isInitialized, setInitialized] = useState(false);
  // check if there is query params
  useEffect(() => {
    if (router.isReady) {
      setSort(router.query?.sort?.toString());
      //@ts-ignore
      setOrder(router.query?.order?.toString());
      setPageSize(Number(router.query?.perPage ? router.query?.perPage : 25));
      console.log(
        '----------/--------/--------------/--------------specific----------\n',
        router.query,
        '------------fin-specific------'
      );

      setQ(router.query.q ? router.query.q.toString() : '');

      // we will set 'specific' object from the specific.items
      Object.keys(router.query ?? {})
        .filter(i => i.includes('specific.'))
        .forEach(item => {
          console.log(item);
          //@ts-ignore
          setSpecific(item.split('.')[1], _.get(router.query, item));
        });

      setInitialized(true);

      return () => {
        clearAll();
      };
    }
  }, [router.query]);
  return { isInitialized };
};

export default useInitializer;
