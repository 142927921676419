import Link from 'next/link';
import React, { FC, useEffect, useRef, useState } from 'react';
import Joyride, {
  ACTIONS,
  CallBackProps,
  STATUS,
  Step,
  StoreHelpers,
  LIFECYCLE,
} from 'react-joyride';
import { Button, IconGlyphCheck, IconGlyphTimes, IconGlyphBook, IconClipboard, Loader } from '@phpcreation/frontend-components-react';
import { useGetAPIStatus } from '@/lib/services/api-config/common';
import toast, { Toaster } from 'react-hot-toast';
import { useGetToken } from '@/hooks/auth/useLogin';
import Head from 'next/head';
import { IFQCN_BUI } from '@/utils/types/common';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { API_ENDPOINTS } from '@/utils/constants/constants';

type Props = {
  fqcn_bui: IFQCN_BUI;
};

const Status: FC<Props> = ({ fqcn_bui }) => {
  const { isLoading, isSuccess, data, isRefetching, isError, refetch, status } =
    useGetAPIStatus();

  const {
    isLoading: isLoadingToken,
    isSuccess: isSuccessGetToken,
    mutate: getToken,
    data: dataToken,
  } = useGetToken();

  const copyToken = (token: string) => {
    window.navigator.clipboard.writeText(`bearer ${token}`);
    toast.success('copied');
  }

  useEffect(() => {
    //@ts-ignore
    if (API_ENDPOINTS.status && !data?.data.Status) {
      refetch();
    }
  }, [API_ENDPOINTS.status, data]);

  //@ts-ignore
  if (isLoading || isRefetching || !data?.data.Status) return <Loader isLoading={true} />

  return (
    <div
      className="flex flex-col min-h-screen"
      style={{ fontFamily: 'Roboto' }}
    >
      <Head>
        <title>Status</title>
        <meta name="description" content="phpreaction prototype" />
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <Toaster />
      <main
        id={getFQCN(fqcn_bui, '')}
        className="flex flex-col pt-10 items-center h-screen bg-gray-100"
      >
        <h1 className="text-2xl font-medium p-3 pt-2 pb-7">Backend Status </h1>

        <div className="__TAble flex flex-col  space-y-2 p-3 border border-gray-300  shadow-sm rounded-lg   bg-white ">
          <div className="__HEADER grid grid-cols-5 gap-4">
            <span
              id={getFQCN(fqcn_bui, 'endpoint', 'endpoint-text')}
              className="text-sm font-medium text-gray-600 "
            >
              Endpoint
            </span>
            <span className="text-sm font-medium text-gray-600 ">
              Status Code
            </span>

            <span className="text-sm font-medium text-gray-600 ">Status</span>
            <span className="text-sm font-medium text-gray-600 ">Sumarry</span>
            <span className="text-sm font-medium text-gray-600 ">Documentation</span>
          </div>
          {(isLoading || isRefetching) && (
            <div className="__Row_Loding grid grid-cols-5 gap-4 animate-pulse">
              {[1, 2, 3, 4, 5].map(i => (
                <span
                  className={`${
                    i == 1 ? 'w-40 md:w-72' : 'w-28 md:w-36'
                  } h-2 bg-gray-200`}
                  key={i}
                ></span>
              ))}
            </div>
          )}

          {!isLoading && !isRefetching && (
            <div className="__Row grid grid-cols-5 gap-4 ">
              <span className=" font-medium text-gray-800  md:text-base text-sm">
                /api/login_check
              </span>

              <span
                id={getFQCN(fqcn_bui, 'status', 'status')}
                className=" font-medium text-gray-800  md:text-base text-sm"
              >
                {
                  //@ts-ignore
                  data?.status
                }
              </span>
              <span
                className=" font-medium text-gray-800  md:text-base text-sm "
                // onClick={() => console.log(data?.data)}
              >
                {
                  //@ts-ignore
                  data?.data.Status
                }
              </span>
              <div>
                {
                  //@ts-ignore
                  data?.data.Status.toLowerCase() == 'ok' ? (
                    <span>
                      <IconGlyphCheck
                        fill="green"
                        className="w-10 h-10 text-green-600"
                      />
                    </span>
                  ) : (
                    <span>
                      <IconGlyphTimes
                        fill="green"
                        className="w-10 h-10 text-red-600"
                      />
                    </span>
                  )
                }
              </div>
              <a
                href={API_ENDPOINTS.documentation} // TODO url from project config
                className=" font-medium text-blue-700 underline md:text-base text-xs md:w-auto w-2 "
                target="_blank"
                rel="noreferrer"
              >
                <IconGlyphBook
                  fill="black"
                  className="w-10 h-10"
                />
              </a>
            </div>
          )}
        </div>

        <div className="__Actions w-full p-10 flex justify-center">
          <div className="  w-80  p-3 space-y-4">
            <div className="grid grid-cols-2 gap-10">
              <Button size="small" title="Test Api" onClick={() => refetch()} />
              <Button
                size="small"
                variant={'secondary'}
                title="Get Token"
                onClick={() =>
                  getToken({
                    username: 'admin',
                    password: 'phpc',
                  })
                }
              />
            </div>
          </div>
        </div>

        {isLoadingToken && (
          <div className="w-80 h-3 bg-gray-300 animate-pulse"></div>
        )}

        {isSuccessGetToken && dataToken && (
          <div className="flex flex-col items-center container">
            <p>Token</p>
            <div
              onClick={() => copyToken(dataToken.token)}
              className="__Token flex flex-col  space-y-2 p-3 border border-gray-300  shadow-sm rounded-lg   bg-white cursor-pointer hover:text-gray-700  "
            >
              <p className='overflow-hidden truncate w-80'>
                {'bearer ' + dataToken.token}
              </p>
            </div>

            <span 
              onClick={() => copyToken(dataToken.token)}
              className="text-sm font-light text-gray-500 p-1 italic flex items-stretch space-x-1">
              <span className='self-center'>Click to copy</span>
              <IconClipboard className="w-5 h-5 text-gray-500"/>
            </span>
          </div>
        )}
      </main>

      <footer className=""></footer>
    </div>
  );
};
export default Status;
