import axiosConfigApiInstance from '@/lib/Axios/ConfigApiAxiosInstance';
import { generate_multiNqueryString } from '@/lib/helpers/filters/methods';
import { get_urlQS } from '@/lib/helpers/methods';
import { IResourceFilters } from '@/utils/types/common';

export const get_all_resource = async (
  resource: string,
  filters: IResourceFilters,
  raw: boolean
) => {
  let url = `/${resource}?${get_urlQS(resource, filters)}`;
  console.log('filters:', filters);
  console.log('urlqs:', get_urlQS(resource, filters));
  console.log('----------------------------- url --------');
  console.log(url);
  console.log('-----------------------------');

  return axiosConfigApiInstance.get(url).then(res => {
    if (raw) return res.data;
    return res.data['hydra:member'];
  });
};

export const get_single_resource = async (
  resource: string,
  id: number | string
) => axiosConfigApiInstance.get(`/${resource}/${id}`).then(res => res.data);

export const create_resource = (resource: string, data: any) =>
  axiosConfigApiInstance
    .post(`/${resource}`, data)
    .then(res => res.data['hydra:member']);

export const delete_resource = (resource: string, id: string | number) =>
  axiosConfigApiInstance
    .delete(`/${resource}/${id}`)
    .then(res => res.data['hydra:member']);

export const update_resource = (resource: string, data: any) =>
  axiosConfigApiInstance
    .put(`/${resource}/${data.id}`, data)
    .then(res => res.data['hydra:member']);
