import React, { useEffect, useState } from 'react';
import { Button, Select, IconGlyphCopy, IconGlyphTimes, Input } from '@phpcreation/frontend-components-react';
import { FilterFieldItem } from '@/utils/types/common';
import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import {
  copy_url_with_filters,
  Get_select_value,
} from '@/lib/helpers/filters/methods';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import { get_all_resource } from '@/lib/services/api-config/crud';
import { object } from 'yup';
import useTranslation from '@/hooks/shared/translation/useTranslation';

type Props = {
  isVisible: boolean;
  resource: string;
  filterFields: FilterFieldItem[];
};

const MainFilters: React.FC<Props> = ({
  isVisible,
  resource,
  filterFields,
}) => {
  const queryClient = useQueryClient();

  const { filters, setLimit, setPage, setOffset, clearFilters, setSpecific } =
    useListingStore();

  const [selection, setSelection] = useState<any>({});

  const [show, setShow] = useState(false);

  const router = useRouter();

  // // populate choices
  useEffect(() => {
    if (router.isReady) {
      const { query } = router;
      let temp_selection: any = {};

      // show the filter card in case there is a 'specific ' item
      setShow(Object.keys(query).some(i => i.includes('specific')));

      // only get ['id','filter_name'...] from {'specifi.id':'value','specific.fi2':value}
      const QS_for_filters = Object.keys(query)
        .filter(i => i.includes('specific.'))
        .map(s => s.split('.')[1]);

      // ['id','filtername2']
      QS_for_filters.forEach(qs => {
        console.log('\n \n ', qs, ' \n');

        let _filterFieldItem = filterFields.find(i => i.key == qs);

        if (_filterFieldItem?.type == 'search') {
          temp_selection[_filterFieldItem.key] = query['specific.' + qs];
        }

        if (_filterFieldItem?.type == 'select_async') {
          if (_filterFieldItem.isMulti) {
            temp_selection[_filterFieldItem.key] =
              _filterFieldItem.optionsArrayFinite?.filter(i =>
                query['specific.' + qs]
                  ?.toString()
                  .split(',')
                  ?.includes(i.value)
              );
          } else {
            temp_selection[_filterFieldItem.key] =
              _filterFieldItem.optionsArrayFinite?.find(i =>
                query['specific.' + qs]?.includes(i.value)
              );
          }
        }
      });
      setSelection(temp_selection);
      console.log('query is : \n\n\n', query);
    }
  }, [router.isReady]);

  //translate
  const { t } = useTranslation();

  if (!isVisible && !show) return null;

  return (
    <div className="relative z-10 w-full flex items-center justify-between flex-wrap p-2 m-1 mt-2 border rounded-lg border-gray-100 ">
      <div className="Title">
        <span
          className="text-sm text-gray-500 font-medium"
          onClick={() => console.log(filters)}
        >
          {t('Filters')}
        </span>
      </div>
      <div className="flex items-center justify-between flex-wrap  ">
        {filterFields
          .filter(f => f.type == 'select')
          .map((item, key) => (
            <div className="px-1" key={key}>
              <Select
                variant="normal"
                placeholder={t(item.placeholder)}
                isMulti={item.isMulti}
                options={item.optionsArrayFinite}
                onChange={(newIds: any) => {
                  console.log({ newIds });
                  selection[item.key] = newIds;
                  setSelection({ ...selection });
                }}
                value={selection[item.key] ?? ''}
              />
            </div>
          ))}

        {filterFields
          .filter(f => f.type == 'select_async')
          .map((item, key) => (
            <div className="px-1" key={key}>
              <Select
                variant="async"
                placeholder={t(item.placeholder)}
                isMulti={item.isMulti}
                defaultOptions
                cacheOptions={true}
                loadOptions={(input, callback) => {
                  get_all_resource(
                    item.targetResourceAsync ?? item.key + 's',
                    { id: input },
                    false
                  )
                    .then(res =>
                      res.map((i: any) => {
                        return { label: i.id, value: i.id };
                      })
                    )
                    .then(data => callback(data));
                }}
                onChange={(newIds: any) => {
                  console.log({ newIds });
                  const new_selections = { ...selection };
                  new_selections[item.key] = newIds;

                  console.log({ new_selections });
                  setSelection(new_selections);
                }}
                value={selection[item.key] ?? ''}
              />
            </div>
          ))}

        {filterFields
          .filter(f => f.type == 'search')
          .map((item, key) => (
            <div className="px-1" key={key}>
              <Input
                placeholder={t(item.placeholder)}
                onChange={(event: any) => {
                  console.log({ value: event.target.value });
                  const new_selections = { ...selection };
                  new_selections[item.key] = event.target.value;
                  console.log({ new_selections });
                  setSelection(new_selections);
                }}
                value={selection[item.key] ?? ''}
              />
            </div>
          ))}
      </div>

      <div className="flex  items-center  space-x-3 w-60  ">
        <Button
          className="text-gray-500 hover:text-red-500 text-xs font-medium "
          variant="neutral"
          color="red-400"
          onClick={() => {
            clearFilters();
            setSelection({});
            console.log(selection);
          }}
          icon={
            <IconGlyphTimes className="w-6 h-6 text-gray-600" fill="gray" />
          }
        />

        <Button
          className="text-gray-500 hover:text-red-500 text-xs font-medium "
          variant="neutral"
          color="red-400"
          onClick={() => {
            copy_url_with_filters(filters);
          }}
          icon={<IconGlyphCopy className="w-6 h-6 text-gray-600" fill="gray" />}
        />
        <Button
          title={t('apply')}
          variant="neutral"
          onClick={() => {
            console.log('------------applyclick-------------');

            let arr_object_from_keys = Object.keys(selection);
            console.log({ arr_object_from_keys });
            arr_object_from_keys.forEach(item => {
              // in case the filter field is  select, so we can convert from key: {lable:,value:} to : key:value
              if (
                filterFields.find(f => f.key == item)?.type.includes('select')
              ) {
                if (filterFields.find(f => f.key == item)?.isMulti == true) {
                  setSpecific(
                    item,
                    selection[item].map((i: any) => i.value).toString()
                  );
                }
                if (!filterFields.find(f => f.key == item)?.isMulti) {
                  setSpecific(item, selection[item].value.toString());
                }
              } else {
                setSpecific(item, selection[item]);
              }

              console.log('selection ', item, selection[item]);
              // setSpecific(item, selection[item]);
            });
            console.log('-------------------------');
          }}
        />
      </div>
    </div>
  );
};

export default MainFilters;
