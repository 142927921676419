import { Button, Select, Input, PageLayout, LinkButton } from '@phpcreation/frontend-components-react';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import { submitDataModifier } from '@/lib/helpers/methods';
import { get_all_resource, useCreate } from '@/lib/services/api-config/crud';
import {
  FormInput,
  FormInputItem,
  IFQCN_BUI,
  Navigation,
} from '@/utils/types/common';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useState } from 'react';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import Link from 'next/link';
import parse from 'html-react-parser';

type CreateProps = {
  resource: string;
  appNav: Navigation[];
  formInputs: FormInputItem[];
  fqcn_bui: IFQCN_BUI;
};

const Create: FC<CreateProps> = ({
  resource,
  appNav,
  formInputs,
  fqcn_bui,
}) => {
  const router = useRouter();

  const { t } = useTranslation();

  //@ts-ignore
  const {
    data: dataCreate,
    isLoading: isLoadingCreate,
    isSuccess: isSuccessCreate,
    mutate: create_resource,
  } = useCreate(resource);

  const [FormData, setFormData] = useState<any>({});

  useEffect(() => {
    let key_value_formData = {};
    formInputs.forEach(i => {
      //@ts-ignore
      key_value_formData[i.name] = router.query[i.name] ?? '';
    });
    setFormData(key_value_formData);
  }, [router.isReady]);
  // if (isError) return <p>eror</p>;
  return (
    <PageLayout
      fqcn_bui={fqcn_bui}
      title={t('Add ') + t(resource.toString().slice(0, resource.length - 1))}
      appNav={appNav}
      breadcrumbNav={[
        { label: 'Home', url: '/' },
        { label: resource, url: `/${resource}`},
        { label: 'new', url: '#' },
      ]}
    >
      <div className="py-4">
        <form
          id={getFQCN(fqcn_bui, 'form')}
          className="flex flex-col items-start space-y-1"
        >
          {
            //@ts-ignore
            formInputs.map((field: FormInputItem, index: any) => {
              if (field.type == 'text')
                return (
                  <div
                    key={index}
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {t(field.name)}
                    </label>

                    <Input
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      // size="large"
                      onChange={e =>
                        setFormData({
                          ...FormData,
                          [field.name]: e.target.value,
                        })
                      }
                      value={FormData[field.name] ?? ''}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                    />
                  </div>
                );

              // CASE  Finite
              if (field.type == 'select')
                return (
                  <div
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {field.name}
                    </label>

                    <Select
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                      options={field.optionsArrayFinite}
                      onChange={(newIds: any) => {
                        console.log({ newIds });
                        setFormData({
                          ...FormData,
                          [field.name]: newIds.value,
                        });
                      }}
                      value={{
                        label: FormData[field.name],
                        value: FormData[field.name],
                      }}
                    />
                  </div>
                );

              // CASE Not Finite== Load from server
              if (
                field.type == 'select_async' &&
                field.targetResourceAsync?.length
              )
                return (
                  <div
                    id={getFQCN(fqcn_bui, 'form', field.name)}
                    className="flex flex-col items-start -space-y-2 text-sm"
                  >
                    <label
                      id={getFQCN(fqcn_bui, 'form', field.name + '-label')}
                    >
                      {t(field.name)}
                    </label>

                    <Select
                      id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                      variant="async"
                      placeholder={t(field.placeholder ? field.placeholder : '')}
                      defaultOptions
                      cacheOptions={true}
                      loadOptions={(input, callback) => {
                        get_all_resource(
                          `${field.targetResourceAsync}`,
                          { id: input },
                          false
                        )
                          .then(res =>
                            res.map((i: any) => {
                              return { 
                                label: field.resourceAsyncLabelParam ? parse(i[field.resourceAsyncLabelParam]) : i.id,
                                value: i.id 
                              };
                            })
                          )
                          .then(data => callback(data));
                      }}
                      onChange={(newIds: any) => {
                        console.log({ newIds });
                        setFormData({
                          ...FormData,
                          [field.name]: newIds,
                        });
                      }}
                      value={FormData[field.name]}
                    />
                  </div>
                );
            })
          }
          <div className="flex w-full justify-center space-x-2  p-3">
            <Link href={`/${resource}`} passHref legacyBehavior>
              <LinkButton
                id={getFQCN(fqcn_bui, 'form', 'button-cancel')}
                href={`/${resource}`}
                onClick={() => {}}
                variant="secondary"
                title={t('Cancel')}
              />
            </Link>
            <Button
              id={getFQCN(fqcn_bui, 'form', 'button-submit')}
              title={t("Create")}
              isLoading={isLoadingCreate}
              onClick={(event) => {
                event.preventDefault();

                let adjusted_formData = submitDataModifier(
                  FormData,
                  formInputs
                );

                //@ts-ignore
                create_resource(adjusted_formData);
              }}
            />
          </div>
        </form>
      </div>
    </PageLayout>
  );
};

export default Create;
