import React, { FC, useEffect, useState } from 'react';
import { Button, Select, Input, PageLayout, LinkButton } from '@phpcreation/frontend-components-react';
import {
  get_all_resource,
  useReadSingle,
  useUpdate,
  get_single_resource
} from '@/lib/services/api-config/crud';
import { FormInput, FormInputItem, IFQCN_BUI } from '@/utils/types/common';
import _ from 'lodash';
import { useRouter } from 'next/router';

import { Navigation } from '@/utils/types/common';
import { submitDataModifier } from '@/lib/helpers/methods';
import { getFQCN } from '@/lib/helpers/fqcn/methods';
import useTranslation from '@/hooks/shared/translation/useTranslation';
import Link from 'next/link';
import parse from 'html-react-parser';

type EditProps = {
  resource: string;
  appNav: Navigation[];
  formInputs: FormInputItem[];
  fqcn_bui: IFQCN_BUI;
};

const Edit: FC<EditProps> = ({ resource, appNav, formInputs, fqcn_bui }) => {
  const router = useRouter();
  const { t } = useTranslation();
  const { id } = router.query;
  //@ts-ignore
  const { data, isLoading, isSuccess, isError } = useReadSingle(resource, id);
  const {
    data: dataUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
    mutate: update_resource,
  } = useUpdate(resource);

  const [FormData, setFormData] = useState<any>({});

  useEffect(() => {
    let key_value_formData = {};

    if (isSuccess) {
      formInputs.forEach(async (i: FormInputItem) => {

        if (i.type == 'text') {
          //@ts-ignore
          key_value_formData[i.name] =
          //@ts-ignore
            router.query[i.name] ??
            _.get(data, i.itemSpecificPath ?? i.name);
        }

        if (i.type == 'select_async') {
          const id = Number(data[i.name].replace(i.prefixOnSubmit, ''));

          //@ts-ignore
          key_value_formData[i.name] = {
            label: '',
            value: id,
          }

          const resource = await get_single_resource(`${i.targetResourceAsync}`, data[i.name].replace(i.prefixOnSubmit, ''));

          //@ts-ignore
          key_value_formData[i.name].label = i.resourceAsyncLabelParam ? 
            parse(resource[i.resourceAsyncLabelParam]) : id;
        }
      });

      setFormData(key_value_formData);
    }
  }, [isSuccess]);

  if (isError) return <p>eror</p>;
  return (
    <PageLayout
      fqcn_bui={fqcn_bui}
      title={t('Edit ') + t(resource.toString().slice(0, resource.length - 1))}
      breadcrumbNav={[
        { label: 'Home', url: '/' },
        { label: resource, url: `/${resource}`},
        { label: id ? id.toString() : 'd', url: '#' },
        { label: 'edit', url: '/#' },
      ]}
      appNav={appNav}
    >
      <form
        id={getFQCN(fqcn_bui, 'form')}
        className="flex flex-col items-start space-y-1 py-4"
      >
        <div
          id={getFQCN(fqcn_bui, 'form', 'id')}
          className="flex flex-col items-start -space-y-2 text-sm"
        >
          <label id={getFQCN(fqcn_bui, 'form', 'id-label')}>
            {t(resource + ' ID')}
          </label>
          <Input
            id={getFQCN(fqcn_bui, 'form', 'id-input')}
            // size="large"
            disabled
            placeholder={id?.toLocaleString()}
          />
        </div>

        {
          //@ts-ignore
          formInputs.map((field: FormInputItem, index: any) => {
            if (field.type == 'text')
              return (
                <div
                  key={index}
                  id={getFQCN(fqcn_bui, 'form', field.name)}
                  className="flex flex-col items-start -space-y-2 text-sm"
                >
                  <label id={getFQCN(fqcn_bui, 'form', field.name + '-label')}>
                    {t(field.label)}
                  </label>

                  <Input
                    id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                    // size="large"
                    onChange={e =>
                      setFormData({
                        ...FormData,
                        [field.name]: e.target.value,
                      })
                    }
                    value={FormData[field.name] ?? ''}
                    placeholder={t(field.placeholder ? field.placeholder : '')}
                  />
                </div>
              );

            // CASE  Finite
            if (field.type == 'select')
              return (
                <div
                  id={getFQCN(fqcn_bui, 'form', field.name)}
                  className="flex flex-col items-start -space-y-2 text-sm"
                >
                  <label id={getFQCN(fqcn_bui, 'form', field.name + '-label')}>
                    {t(field.label)}
                  </label>

                  <Select
                    id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                    placeholder={t(field.placeholder ? field.placeholder : '')}
                    options={field.optionsArrayFinite}
                    onChange={(newIds: any) => {
                      console.log({ newIds });
                      setFormData({
                        ...FormData,
                        [field.name]: newIds.value,
                      });
                    }}
                    value={{
                      label: FormData[field.name],
                      value: FormData[field.name],
                    }}
                  />
                </div>
              );

            // CASE Not Finite== Load from server
            if (
              field.type == 'select_async' &&
              field.targetResourceAsync?.length
            )
              return (
                <div
                  id={getFQCN(fqcn_bui, 'form', field.name)}
                  className="flex flex-col items-start -space-y-2 text-sm"
                >
                  <label id={getFQCN(fqcn_bui, 'form', field.name + '-label')}>
                  {t(field.label)}
                  </label>

                  <Select
                    id={getFQCN(fqcn_bui, 'form', field.name + '-input')}
                    variant="async"
                    placeholder={t(field.placeholder ? field.placeholder : '')}
                    defaultOptions
                    cacheOptions={true}
                    loadOptions={(input, callback) => {
                      get_all_resource(
                        `${field.targetResourceAsync}`,
                        { id: input },
                        false
                      )
                        .then(res =>
                          res.map((i: any) => {
                            return { 
                              label: field.resourceAsyncLabelParam ? parse(i[field.resourceAsyncLabelParam]) : i.id,
                              value: i.id 
                            };
                          })
                        )
                        .then(data => callback(data));
                    }}
                    onChange={(newIds: any) => {
                      console.log({ newIds });
                      setFormData({
                        ...FormData,
                        [field.name]: newIds,
                      });
                    }}
                    value={FormData[field.name]}
                  />
                </div>
              );
          })
        }

        <div className="flex w-full justify-center space-x-2  p-3">
          <Link href={`/${resource}/${id}`} passHref legacyBehavior>
            <LinkButton
              id={getFQCN(fqcn_bui, 'form', 'button-cancel')}
              href={`/${resource}`}
              onClick={() => {}}
              variant="secondary"
              title={t('Cancel')}
            />
          </Link>
          <Button
            id={getFQCN(fqcn_bui, 'form', 'button-submit')}
            title={t("Edit")}
            isLoading={isLoadingUpdate}
            onClick={event => {
              event.preventDefault();
              console.log(FormData);
              // adding this in case of relationtional entities
              let adjusted_formData = submitDataModifier(FormData, formInputs);

              //@ts-ignore
              update_resource({
                id: Number(id),
                ...adjusted_formData,
              });
            }}
          />
        </div>
      </form>
    </PageLayout>
  );
};

export default Edit;
