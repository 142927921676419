import React, { FC, useEffect, useState } from 'react';
import { IconSearch, Input } from '@phpcreation/frontend-components-react';
import useDebounce from '@/hooks/shared/useDebounce';
import useListingStore from '@/lib/global-stores/shared/listing/useListingStore';
import useTranslation from '@/hooks/shared/translation/useTranslation';

type Props = {
  id?: string;
  qField: string;
};

const ListingSearch: FC<Props> = ({ id, qField }) => {
  const { setQ, filters, setQField } = useListingStore();

  const [searchKeyword, setSearchKeyword] = useState(filters.q ?? '');
  const _debouncedSearchKeyword = useDebounce(searchKeyword);

  // debounced search effect
  useEffect(() => {
    setQField(qField);
    setQ(_debouncedSearchKeyword.trim());
    console.log(searchKeyword);
    console.log({ q: filters.q });
  }, [_debouncedSearchKeyword]);

  //translate
  const { t } = useTranslation();
  return (
    <div className="md:w-fit w-96" id={'search_bar'}>
      <Input
        id={id}
        placeholder={t("search")}
        value={searchKeyword}
        onChange={e => {
          setSearchKeyword(e.target.value);
        }}
        onKeyDownCapture={e => {
          if (e.key == 'Escape') setSearchKeyword('');
        }}
        icon={<IconSearch className="w-3 h-3 text-gray-400" />}
        isClearable={searchKeyword.length != 0}
        handleClearField={() => setSearchKeyword('')}
      />
    </div>
  );
};

export default ListingSearch;
