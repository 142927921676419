import { TourStoreItem } from '@/utils/types/common';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';

const useTourStore = create(
  combine(
    {
      tours: [] as TourStoreItem[],
    },

    set => ({
      createTour: (id: string) =>
        set(s => ({ tours: [...s.tours, { id, isRun: false }] })),

      runTour: (id: string) =>
        set(s => ({
          tours: s.tours.map(t => {
            console.log(s.tours);
            if (t.id === id) return { id, isRun: true };
            return t;
          }),
        })),
      stopTour: (id: string) =>
        set(s => ({
          tours: s.tours.map(t => {
            if (t.id === id) return { id, isRun: false };
            return t;
          }),
        })),

      stopAll: () =>
        set(s => ({
          tours: s.tours.map(t => ({ id: t.id, isRun: false })),
        })),
    })
  )
);

export default useTourStore;
