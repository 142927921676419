import { string } from 'yup';
import { create } from 'zustand';
import { combine } from 'zustand/middleware';

const useModalsStore = create(
  combine(
    {
      modalAdd: {
        isOpen: false as boolean,
        data: null as any,
      },
      modalEdit: {
        isOpen: false as boolean,
        data: null as any,
      },
      modalDuplicate: {
        isOpen: false as boolean,
        data: null as any,
      },
      modalDelete: {
        isOpen: false as boolean,
        data: null as any,
      },
    },

    set => ({
      setModalAdd: (isOpen: boolean, data?: any) =>
        set(s => ({ modalAdd: { isOpen, data } })),
      setModalEdit: (isOpen: boolean, data?: any) =>
        set(s => ({ modalEdit: { isOpen, data } })),
      setModalDuplicate: (isOpen: boolean, data?: any) =>
        set(s => ({ modalDuplicate: { isOpen, data } })),
      setModalDelete: (isOpen: boolean, data?: any) =>
        set(s => ({ modalDelete: { isOpen, data } })),

      closeAllModals: () =>
        set({
          modalAdd: {
            isOpen: false,
            data: null,
          },
          modalEdit: {
            isOpen: false,
            data: null,
          },
          modalDelete: {
            isOpen: false,
            data: null,
          },
          modalDuplicate: {
            isOpen: false,
            data: null,
          },
        }),
    })
  )
);

export default useModalsStore;
